<template>
  <div
    class="main-content gen-header-bg full-h"
    v-loading="$h.isSubmitting() || $h.isFetching()"
  >
    <!-- Header -->
    <div class="header py-6 py-md-8 gen-header-bg">
      <div class="position-absolute" style="top: 30px; right: 40px">
        <el-dropdown trigger="click" :hide-on-click="true" @command="changeLang">
          <span class="el-dropdown-link">
            <span class="c-pointer">{{
              $configs.langList.find((it) => it.code == $t.getLanguage()).name
            }}</span>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="lang in $configs.langList"
                :key="lang.code"
                :command="lang.code"
                >{{ $t.translate(lang.name) }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>

      <div class="container">
        <div class="header-body text-center mb-5 mb-md-2"></div>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-6">
      <router-view></router-view>
    </div>
    <footer class="fixed-cr">
      <div class="container">
        <div class="row align-items-center justify-content-xl-between">
          <div class="col-xl-12">
            <div class="copyright text-center text-muted h5 mb-0">
              &copy; {{ year }}
              <span class="font-weight-bold ml-1">HKQAA</span>
            </div>

            <p class="clipped-text text-center text-muted">
              Created by
              <a href="http://www.codaplant.com" target="_blank"> CodaPlant </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import { onMounted, ref } from "vue";
import { store } from "@/store";
import { router } from "@/utils/router";
import { useRoute } from "vue-router";

export default {
  setup() {
    const route = useRoute();
    let year = ref(new Date().getFullYear());

    const changeLang = async (val) => {
      await store.dispatch("translation/setData", { languageCode: val });
    };

    return { year, changeLang };
  },
};
</script>
<style></style>
